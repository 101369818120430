//import React from 'react'
import { useState, useEffect } from 'react'
import { ImTelegram } from 'react-icons/im';
import { Modal, Button } from 'rsuite'

const localStorageKeyName = "shownews"

export const SiteNewsModal = () => {

    const [open, setOpen] = useState(true);
    const [backdrop, setBackdrop] = useState('static');
    const [checked, setChecked] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [shownews, setShowNews] = useState(() => {
        const localData = localStorage.getItem(localStorageKeyName);
        if (localData) { setOpen(JSON.parse(localData)); }
        return localData ? JSON.parse(localData) : true;
    });

    //const handleCheckChange = () => setChecked(!checked);
    const handleCheckChange = () => {
        setChecked(!checked)
        setShowNews(!shownews)
    }


    // set localstorage when it changes
    useEffect(() => {
        // get local storage info
        localStorage.setItem(localStorageKeyName, JSON.stringify(shownews));
    }, [shownews]);

    return (
        <Modal open={open} backdrop={backdrop} onClose={handleClose} >
            <Modal.Header>
                <Modal.Title id="modal-title">Threatline.io Early Availability</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-description">
                <p>This project is current in active development, so please bear with us while we get this stuff figured out.
                    We hope it proves useful to you as we continue to add features. </p>
                {/* <p>You may also enjoy subscribing to our Telegram Channel for live Cyber Security News alerts <ImTelegram /></p> */}
            </Modal.Body>
            <Modal.Footer >
                <div className='modalButtonDiv'>
                    <div>
                        <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={checked}
                            onChange={handleCheckChange}
                        />
                        {<label htmlFor="checkbox">    Don't show me again</label>}
                    </div>
                    <div>
                        <Button appearance="primary" onClick={handleClose}>Close</Button>
                    </div>

                </div>
            </Modal.Footer>
        </Modal >
    )
}
