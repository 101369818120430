import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NewsItem } from './NewsItem';
import { ImSpinner10 } from 'react-icons/im';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TagInput } from 'rsuite';

// import { Placeholder } from 'rsuite';

export const SearchPagination = () => {

    const [items, setItems] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [limit, setLimit] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsCount, setSearchResultsCount] = useState(0);

    const getAll = async () => {
        setLoading(true)
        const result = await axios(
            `https://api.threatline.io/api/paginate?page=${page}&limit=${limit}`
        );
        setItems([...items, ...result.data.items]);

        setLoading(false)
        // console.log(`${page}/${result.data.totalPages}`)
        setPage(page + 1);
        if (page === result.data.totalPages) {
            setHasMore(false);
            //console.log("the end")

        }
    };

    const fetchData = async () => {
        setLoading(true)
        if (searchTerm) {
            console.log("search term found")
            if (searchTerm.length > 0) {
                console.log("search term length > 0")
                console.log(`getting data for ${searchTerm}`);
                const result = await axios(
                    `https://api.threatline.io/api/searchp?page=${page}&limit=${limit}&searchTerms=${searchTerm}`
                );
                if (result.data.items.length < 1) {
                    setHasMore(false);
                    console.log("no results found")
                    return
                }

                setSearchResultsCount(result.data.totalResults);
                setItems([...items, ...result.data.items]); //reset results array
                console.log(result.data)
                setLoading(false)
                setPage(page + 1);
                if (page === result.data.totalPages) {
                    setHasMore(false);
                    //console.log("the end")

                }
            } else {

                getAll()
            }
        } else {

            getAll()
        }
    };

    function updateSearchTerm(value) {
        //reset page
        console.log("reset page to 1")
        setPage(1);
        setSearchTerm(value);
        setItems([]);
        setHasMore(true);
        setSearchResultsCount(0);
    }


    useEffect(() => {
        console.log("useEffect, searchterm has changed")
        fetchData();
    }, [searchTerm])

    return (
        <>

            <TagInput
                className="tagInput"
                //trigger={['Enter', 'Space', 'Comma']}
                trigger={['Enter']}
                placeholder="enter search terms..."
                style={{ width: 300 }}
                menuStyle={{ width: 300 }}
                onChange={(value, item) => {
                    updateSearchTerm(value)
                }}
                onClean={(value, item) => {
                    updateSearchTerm(null)
                }}
            />

            {(searchResultsCount > 0) ? <h6>{searchResultsCount} results</h6> : null}


            <InfiniteScroll
                dataLength={items.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>No more results</b>
                    </p>
                }
            >
                {items.map((candidate, index) => {
                    return <NewsItem item={candidate} key={index} />
                })}
            </InfiniteScroll>



        </>
    )

};

