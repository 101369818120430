import { News } from './components/News';
import { Search } from './components/Search';
import { NewsPagination } from './components/NewsPagination';
import { SiteNewsModal } from './components/SiteNewsModal';
import { SearchPagination } from './components/SearchPagination';
import { Redirect } from './components/Redirect';
import './App.css';
import 'rsuite/dist/rsuite.min.css'

// import { Container, Header, Content, Footer, Sidebar } from 'rsuite';

function App() {

  return (
    <div className="App">
      {/* <Container>
        <Sidebar></Sidebar>
        <Container>
          <Header></Header>
          <Content>  <News />  </Content>
          <Footer>Footer</Footer>
        </Container>
      </Container> */}
      <Redirect />
      <SiteNewsModal />
      <SearchPagination />

      {/* <News /> */}
      {/* <SiteNewsModel />
      <NewsPagination /> */}
    </div>



  );
}

export default App;
