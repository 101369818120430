import React from 'react';
import moment from 'moment';

export const NewsItem = ({ item }) => {
  var dateFromObjectId = function (objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
  var date = dateFromObjectId(item._id);

  return (
    <div className='newsDiv'>
      <h4>
        {item.RSSsource}: <a href={item.link}> {item.title}</a>
      </h4>
      <p>{item.content}</p>
      <p style={{ color: 'darkgray' }}>{moment(date).fromNow()}</p>
    </div>
  );
};
